const defineIsNumeric = n => !isNaN(parseFloat(n)) && isFinite(n)

const required = value => {
  const errorText = 'This field is required'

  if (!value) return errorText

  const isBlob = value instanceof Blob
  const isFile = value instanceof File

  if (isBlob || isFile) {
    return Boolean(value.name) || errorText
  }

  if (typeof value === 'number') return true

  if (typeof value === 'string') {
    return Boolean(value.trim().length) || errorText
  }

  if (Array.isArray(value)) {
    return Boolean(value.length) || errorText
  }

  if (typeof value === 'object') {
    return Boolean(Object.keys(value).length) || errorText
  }

  if (typeof value === 'boolean') {
    return value || errorText
  }
}

const numeric = value =>
  value ? defineIsNumeric(value) || 'This field is invalid' : true

const positive = value =>
  value ? Number(value) > 0 || 'This field must be positive' : true

const counter = (value, max) =>
  !value || value.length <= max || `Max ${max} characters`

const between = (value, min, max) =>
  value
    ? Number(value) < min || Number(value) > max
      ? `The value should be between ${min} and ${max}`
      : true
    : true

const email = value =>
  value
    ? /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || 'A full email is required'
    : true

const url = value =>
  value ? /https?:\/\/.+\..+/.test(value) || 'A full URL is required' : true

const objectKey = value =>
  value
    ? /^[a-zA-Z0-9_]*$/.test(value) ||
      'This field is invalid (must not contain special symbols or spaces)'
    : true

export { required, numeric, positive, counter, between, email, url, objectKey }
