<template>
  <div class="gap-[2px] flex flex-initial" style="z-index: 0">
    <v-tooltip
      v-for="tenant of normalizedTenantsList"
      :key="tenant.id"
      :disabled="!isTooltip"
      bottom
      :color="defineIsActive(tenant) ? definePrimaryColor(tenant) : 'gray'"
    >
      <template #activator="{ on, attrs }">
        {{ void (isActive = defineIsActive(tenant)) }}
        {{ void (isInactive = !isActive) }}
        {{ void (primaryColor = definePrimaryColor(tenant)) }}

        <v-avatar
          :class="{
            'grayscale opacity-25': isInactive,
            'cursor-pointer': $listeners.select
          }"
          :size="isSmall ? '1.5rem' : '2rem'"
          :color="primaryColor"
          v-bind="attrs"
          v-on="on"
          @click.stop="$emit('select', tenant)"
        >
          <v-img
            v-if="tenant?.files?.favicon?.path"
            class="absolute inset-0 h-full w-full"
            :src="tenant.files.favicon.path"
            :alt="`${tenant.name} small logo`"
          />

          <div
            class="w-full h-full flex justify-center items-center text-center"
          >
            <span class="font-bold text-xs text-white leading-none">
              {{ defineTenantAbbr(tenant) }}
            </span>
          </div>
        </v-avatar>
      </template>

      <span>{{ tenant.name }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { defineColorsByTenant } from '@/utils/theme-changer'

export default {
  name: 'AdminBaseTenantAvatars',

  props: {
    list: { type: Array, default: () => [] },
    isSmall: { type: Boolean, default: false },

    // true if should be aligned by the left side
    isRtl: { type: Boolean, default: false },
    // true if should be aligned by the right side
    isLtr: { type: Boolean, default: false },
    isTooltip: { type: Boolean, default: true },
    isShowInactive: { type: Boolean, default: false }
  },

  computed: {
    ...mapState('tenants', ['tenants']),

    idList() {
      const isIdList = typeof this.list?.[0] === 'number'
      const idList = isIdList ? this.list : this.list.map(item => item.id)

      return idList
    },

    normalizedTenantsList() {
      const activeList = this.tenants?.list?.filter(item =>
        this.idList.includes(item.id)
      )
      const allList = structuredClone(this.tenants?.list)

      const normalizedList = (this.isShowInactive ? allList : activeList).sort(
        (a, b) => a.name.localeCompare(b.name)
      )

      if (this.isLtr) return normalizedList
      else if (this.isRtl) return normalizedList?.reverse()

      return normalizedList
    }
  },

  methods: {
    defineIsActive(tenant) {
      return this.idList?.some(id => id === tenant.id)
    },

    definePrimaryColor(tenant) {
      return defineColorsByTenant(tenant).primary
    },

    defineTenantAbbr(tenant) {
      const abbr = tenant?.name.match(/[A-Z]/g).join('')

      return abbr || ''
    }
  }
}
</script>
