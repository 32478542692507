import store from '@/store'
import { defineChildren } from '@/utils/tree'

const NO_RULES = Object.freeze({
  allowedCategoryIdList: [],

  requiredCategoryList: [],
  requiredBestCategoryList: []
})

const aToPa = a => ({ attribute: a })
const paToA = pa => pa.attribute

// Accepts list of attributes and categories
const defineRuledList = ({
  isForAdmin,
  principal,
  isForIndividual,
  rules,
  list
}) => {
  const isMember = store.getters['user/defineIsMember'](principal)
  isForIndividual = principal ? isMember : isForIndividual

  if (isForIndividual) {
    list = list.filter(item => !item.onlyForHubs)
  }

  if (!isForAdmin) {
    list = list.filter(item => !item.forInternalUse)
  }

  if (rules.allowedCategoryIdList?.length) {
    list = list.filter(item => {
      const categoryId = item.attributeCategoryId || item.id

      return rules.allowedCategoryIdList.includes(categoryId)
    })
  }

  return list
}

const defineTreeList = async list => {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('@/workers/attributes-tree-list.js', import.meta.url),
      { type: 'module' }
    )

    worker.onmessage = e => {
      resolve(e.data)
      // worker.terminate()
    }

    worker.onerror = e => {
      reject(e)
      // worker.terminate()
    }

    worker.postMessage(list)
  })
}

const defineFlatList = async list => {
  return new Promise((resolve, reject) => {
    const worker = new Worker(
      new URL('@/workers/attributes-flat-list.js', import.meta.url),
      { type: 'module' }
    )

    worker.onmessage = e => {
      resolve(e.data)
      // worker.terminate()
    }

    worker.onerror = e => {
      reject(e)
      // worker.terminate()
    }

    worker.postMessage(list)
  })
}

// Category rulesJson
const DEFAULT_MAX_QUANTITY = 5

const defineCategoryRules = category => {
  return category.rulesJson ? JSON.parse(category.rulesJson) : {}
}

const defineCategoryRulesByPrincipal = ({ category, principal }) => {
  const rules = defineCategoryRules(category)
  let { tenantRules = {} } = rules

  tenantRules = principal.tenants.reduce((result, { marker }) => {
    if (tenantRules[marker]) result[marker] = tenantRules[marker]
    return result
  }, {})

  return { ...rules, tenantRules }
}

const defineIsCategoryQuantityLimit = ({ category, principal }) => {
  const rules = principal
    ? defineCategoryRulesByPrincipal({ category, principal })
    : defineCategoryRules(category)

  const isQuantityLimit = Object.values(rules.tenantRules)?.some(
    rule => rule.minQuantity !== 0 || rule.maxQuantity !== DEFAULT_MAX_QUANTITY
  )

  return isQuantityLimit
}

const defineCategoryMergedQuantityLimit = ({ category, principal }) => {
  const rules = principal
    ? defineCategoryRulesByPrincipal({ category, principal })
    : defineCategoryRules(category)

  const minQuantity = Object.values(rules.tenantRules).reduce(
    (result, rule) => Math.max(result, rule.minQuantity || 0),
    0
  )
  const maxQuantity = Object.values(rules.tenantRules).reduce(
    (result, rule) => Math.max(result, rule.maxQuantity),
    DEFAULT_MAX_QUANTITY
  )

  return { minQuantity, maxQuantity }
}

export {
  NO_RULES,
  aToPa,
  paToA,
  defineRuledList,
  defineTreeList,
  defineFlatList,
  defineChildren
}

export {
  DEFAULT_MAX_QUANTITY,
  defineCategoryRules,
  defineCategoryRulesByPrincipal,
  defineIsCategoryQuantityLimit,
  defineCategoryMergedQuantityLimit
}
