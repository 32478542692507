import axios from 'axios'

import {
  formatResponse,
  formatItemResponse,
  formatListResponse
} from '@/utils/api'

const BASE_URL = `/api/attributes`
const URLs = new Proxy(
  {
    FETCH_ALL_WITH_STATS: `/get-all-with-stats`,
    FETCH_ALL_WITH_STATS_ADMIN: `/admin-get-all-with-stats`,
    FETCH_ALL_BY_CATEGORY_ID: `/get-by-category/`,
    // FETCH_ALL_SIMPLE_BY_CATEGORY_ID: `/get-by-category-simple/`,

    MERGE: `/merge`,
    EXPORT_MULTIPLE: `/export`,

    CREATE_ONE: `/create`,
    UPDATE_ONE: `/update`,
    UPDATE_MANY: `/update-many`,
    DELETE_ONE: `/delete/`,
    DELETE_MANY: `/delete-many/`
  },
  { get: (target, prop) => `${BASE_URL}${target[prop]}` }
)

export default {
  async fetchAllWithStats({ isForAdmin }, config = null) {
    const res = isForAdmin
      ? await axios.get(URLs.FETCH_ALL_WITH_STATS_ADMIN, config)
      : await axios.get(URLs.FETCH_ALL_WITH_STATS, config)

    return formatListResponse(res)
  },

  async fetchAllByCategoryId(categoryId) {
    const res = await axios.get(
      `${URLs.FETCH_ALL_BY_CATEGORY_ID}${categoryId}/false/false`
    )

    return formatListResponse(res)
  },
  // async fetchAllSimpleByCategoryId(categoryId) {
  //   const res = await axios.get(
  //     `${URLs.FETCH_ALL_SIMPLE_BY_CATEGORY_ID}${categoryId}`
  //   )

  //   return formatListResponse(res)
  // },

  async merge(payload) {
    const res = await axios.post(URLs.MERGE, payload)

    return formatResponse(res)
  },
  async exportMultiple(payload) {
    const res = await axios.post(URLs.EXPORT_MULTIPLE, payload, {
      responseType: 'blob'
    })

    return res
  },

  async createOne(payload) {
    const res = await axios.post(URLs.CREATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateOne(payload) {
    const res = await axios.put(URLs.UPDATE_ONE, payload)

    return formatItemResponse(res)
  },
  async updateMany(payload) {
    const res = await axios.put(URLs.UPDATE_MANY, payload)

    return formatResponse(res)
  },
  async deleteOne({ id }) {
    const res = await axios.delete(`${URLs.DELETE_ONE}${id}`)

    return formatItemResponse(res)
  },
  async deleteMany(payload) {
    const res = await axios.put(URLs.DELETE_MANY, payload)

    return formatResponse(res)
  }
}
